import {IoMdBoat} from "react-icons/io";

import {TbArrowNarrowLeft, TbLineDotted, TbArrowNarrowRight} from "react-icons/tb";

import React, {useContext, useEffect, useRef, useState} from "react";
import AccountContext from "../../../context/DBContext";
import styles from "./maritimo.module.css"
import {Col, Container, Form, Row} from "react-bootstrap";
import axios from "axios";
import {Typeahead} from "react-bootstrap-typeahead";
import useWindowSize from "../../../hooks/useWindowSize";
import loadingImg from "./loading.gif"
import LangContext from "../../../context/LangContext";
import Bl from "../../../data/bl.json";
import DBContext from "../../../context/DBContext";
import {urlConfig} from "../../../config/urlConfig";
import {onlyNumbers} from "../../../config/functions";

export const TransporteMaritimo = ({claimForm, setClaimForm, stuff, imRsk,editRsk, setStuff,errors,setErrors, exportersName,setExportersName, roleClaim,roleRecover,roleAdmin,handleChangeExporter}) => {
    const {session} = useContext(AccountContext);
    const {currency} = useContext(DBContext);

    const [lista, setLista] = useState([]);
    const [listaBl, setListaBl] = useState([]);
    const [listaBlN, setListaBlN] = useState([]);

    const [listaPol, setListaPol] = useState([]);
    const [listaPod, setListaPod] = useState([]);
    const {height, width} = useWindowSize();
    const [loading, setLoading] = useState(false);
    const [loadingPol, setLoadingPol] = useState(false);
    const [loadingPod, setLoadingPod] = useState(false);
    const {traduccion} = useContext(LangContext);
    const [error, setError] = useState(false);
    const [blLetters, setBlLetters] = useState("");
    const [blNumbers, setBlNumbers] = useState("")
    const [objBl, setObjBl] = useState([(claimForm?.blAwb&&{name:claimForm?.carrierName,scac:claimForm?.blAwb.split("-")[0]})||{name: "",scac: ""}]);
    const [objBlN, setObjBlN] = useState([(claimForm?.blAwb&&{name:claimForm?.carrierName,scac:claimForm?.blAwb.split("-")[0]})||{name: "",scac: ""}]);
    const [receiverList, setReceiverList] = useState([]);
    const [listForInput, setListForInput] = useState([]);

    if (claimForm === null){
        document.location.href="/";
    }
        if (claimForm?.pol === null) {
            claimForm.pol = ""
        }
        if (claimForm?.pod === null) {
            claimForm.pod = ""
        }
        if (claimForm?.vessel === null) {
            claimForm.vessel = ""
        }


    const handleChangeVesselAPI = (e) => {
        setLoading(true)
        axios.get(`https://api.searoutes.com/vessel/v2/${e}/info`, {
            headers: {
                'Accept': 'application/json',
                'x-api-key': process.env.REACT_APP_SEAROUTES
            }
        }).then((res) => {
            setLista(res.data)
            setLoading(false)
        }).catch((error) => {
            ////console.log(error);
            setLoading(false)
        })
    }




    /*------------ SCAC CON LA API (Standard Carrier Alpha Code) ----------------*/
    const handleChangeBlAndCarrierN = (e) => {
        handleChangeBlAndCarrier(e,"name")
    }
    const handleChangeBlAndCarrier = (e,f) => {
        ////console.log(e)
        // //console.log(f)

        ////console.log("HANDLE CHANGE BL AND CARRIER")
        // setObjBl(e)
        ////console.log(objBl)

        if (f === "name") {
            if (e.length>0) {
                setObjBlN(e)
                setClaimForm({
                    ...claimForm,
                    carrierName: e[0].name,
                })
            }
        }else{
            if (e.length>0) {
                setObjBl(e)
                setObjBlN(e)
                setBlLetters(e[0].scac)
                setClaimForm({
                    ...claimForm,
                    carrierName: e[0].name,
                })
            }
        }
    }
    const handleChangeBlN = (e) => {
        handleChangeBl(e,"name")
    }
    const handleChangeBl = (e,f) => {
        //console.log("CAMBIO BL")
        //console.log(e.length>1)
        if(e.length>1) {
            //console.log("MAS DE 1")
            let nombre;
            let blCode;
            if(f==="name"){
                setObjBlN([{name: e, scac: blLetters}])
                setObjBl([{name: e, scac: blLetters}])
                nombre = e
            }else{
                setObjBl([{name: "", scac: e}])
                setBlLetters(e)
                blCode = e;
            }

            const options = {
                method: 'GET',
                url: `${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/searoutes`,
                params: {name: nombre, scac:blCode},

                headers: {
                    Authorization: `${localStorage.getItem("token").replace("Bearer ", "")}`,
                    'x-api-key': process.env.REACT_APP_APIKEY_SEAROUTES,
                }
            };
            axios
                .request(options)
                .then(function (res) {
                    //Si es que hay datos en la respuesta
                    if (res.data.length>0) {
                        //console.log("MAS DE UNO")
                        if(f ==="name") {
                            //console.log(res.data)
                            //console.log("ES DE NOMBRE")
                            //setBlLetters(res.data[0].scac)
                            let nuevaLista = []
                            res.data.forEach((item)=>{
                                nuevaLista.push({
                                    name: item.name,
                                    scac: blLetters
                                })
                            })
                            setListaBlN(nuevaLista)
                            setObjBl([{name: res.data[0].name, scac: blLetters}])
                            setClaimForm({
                                ...claimForm,
                                carrierName: e,
                            })
                        }else{
                            setListaBl(res.data)
                            // setBlLetters(res.data[0].scac)

                            // setClaimForm({
                            //     ...claimForm,
                            //     carrierName: res.data[0].name,
                            // })
                        }

                    }else {
                        //console.log("NO HAY DATOS")
                        //No hay datos en la respuesta
                        if (f === "name") {
                            setListaBlN({
                                ...listaBlN,
                                name: nombre,
                                scac: e
                            })
                            setObjBl([{name: nombre, scac: e}])
                        } else {
                            // setBlLetters(blCode)
                            // setClaimForm({
                            //     ...claimForm,
                            //     carrierName: nombre,
                            // })
                        }
                    }
            }).catch(function (error) {
                if(nombre){
                    setClaimForm({
                        ...claimForm,
                        carrierName: nombre,
                    })
                }else{
                    setBlLetters(blCode)
                }
                console.error(error);
            });
        }else{
            if (f === "name") {
                //console.log("ELSE menor a 2 - ES DE NOMBRE")
                //console.log([{name: e, scac: blLetters}])
                setObjBlN([{name: e, scac: blLetters}])
                setObjBl([{name: e, scac: blLetters}])
                setClaimForm({
                    ...claimForm,
                    carrierName: e,
                })

            }else{
                setObjBl([{name: "", scac: e}])
                setBlLetters(e)
            }
        }
        //console.log(claimForm)
        //console.log(objBl)
        //console.log(objBlN)
    }

    const handleChange = (e) => {
        // console.log("Este es el evento desde maritimo: ", e.target.value)
        // console.log("Este es el evento desde maritimo: ", e.target.name)
        const opciones = e.target.options;
        const valorSeleccionado = e.target.value;
        let textoSeleccionado;

        let value;
        switch (e.target.name) {
            case "currencyId":
                value = parseInt(e.target.value);
                break;
            case "currency":
                value = parseInt(e.target.value);
                break;
            default:
                value = e.target.value;
        }
        if (e.target.name === "ata") {
            setClaimForm({...claimForm,
                ata:e.target.value,
                dischargeDate: e.target.value,
            })
        }

        if (e.target.name === "companyName") {
            //console.log("CAMBIO COMPANY NAME")

            for (let i = 0; i < opciones.length; i++) {
                if (opciones[i].value === valorSeleccionado) {
                    textoSeleccionado = opciones[i].text;
                    //console.log(textoSeleccionado);
                }
            }
            //console.log(opciones)
            //console.log(textoSeleccionado)
            //console.log(value)
            setClaimForm({
                ...claimForm,
                //[e.target.name]: textoSeleccionado,
                exporterName: textoSeleccionado,
                //companyName: textoSeleccionado,
                companyId: value
            })
        }else if (e.target.name === "reclaimedAmount"){

            let addRecoveredCargo = claimForm?.cargo;
            addRecoveredCargo = {
                ...addRecoveredCargo,
                reclaimedAmount:value
            };
            setClaimForm({
                ...claimForm,
                cargo:addRecoveredCargo
            })
        }else if(e.target.name === "currency"){
            let addRecoveredCargo = claimForm?.cargo;
            addRecoveredCargo = {
                ...addRecoveredCargo,
                currency:value
            };
            setClaimForm({
                ...claimForm,
                cargo:addRecoveredCargo
            })
        }else{
            console.log("e.target.name--->", e.target.name)
            setClaimForm({
                ...claimForm,
                [e.target.name]: value
            })
        }
        //console.log(claimForm)
        //change claim on sessionStorage
       // sessionStorage.setItem("claim", JSON.stringify(claimForm))
    }

    const handleChangeVessel = (e) => {
            //console.log(e)
        if (e[0]?.name !== undefined) {
            setClaimForm({
                ...claimForm,
                vessel: e[0].name
            })

        } else {
            setClaimForm({
                ...claimForm,
                vessel: e
            })
            if (e.length >2){
                handleChangeVesselAPI(e)
            }
        }
        //change claim on sessionStorage
       // sessionStorage.setItem("claim", JSON.stringify(claimForm))
    }


    const handleChangePolAPI = (e) => {
            setLoadingPol(true)

            axios.get(`https://api.searoutes.com/geocoding/v2/port?query=${e}`, {
                headers: {
                    'Accept': 'application/json',
                    'x-api-key': process.env.REACT_APP_SEAROUTES
                }
            }).then((res) => {
                setLoadingPol(false)
                //console.log(res.data.ports)
                setListaPol(res.data.features)
            }).catch((error) => {
                setLoadingPol(false)
                //console.log(error);
            })

        if (claimForm.pol === claimForm.pod && claimForm.pol !== "") {
            setError(true)
        }
        if (claimForm.pol !== claimForm.pod) {
            setError(false)
        }
    }


    const handleChangePodAPI = (e) => {
        setLoadingPod(true)

        axios.get(`https://api.searoutes.com/geocoding/v2/port?query=${e}`, {
            headers: {
                'Accept': 'application/json',
                'x-api-key': process.env.REACT_APP_SEAROUTES
            }
        }).then((res) =>{
            setLoadingPod(false)
            setListaPod(res.data.features)
        }).catch((error)=>{
            setLoadingPod(false)
            //console.log(error);
        })

        if(claimForm.pol === claimForm.pod && claimForm.pol !== ""){
            setError(true)
        }
        if(claimForm.pol !== claimForm.pod){
            setError(false)
        }
    }

    const handleChangePol = (e) => {
        if (e[0]?.properties !== undefined) {
            setClaimForm({
                ...claimForm,
                pol: `${e[0].properties.country} - ${e[0].properties.name}`
            })
        } else {
            setClaimForm({
                ...claimForm,
                pol: e
            })
            if (e.length >2) {
                handleChangePolAPI(e)
            }
        }
      //  sessionStorage.setItem("claim", JSON.stringify(claimForm))
    }

    // useEffect(() => {
    //     //modify claim object
    //     setClaimForm({
    //         ...claimForm,
    //         pol: stuff?.puerto_carga,
    //         pod: stuff?.puerto_destino,
    //         vessel: stuff?.motonave,
    //     })
    //
    //
    // }, [stuff])

    const handleChangePod = (e) => {
        console.log({e})
        if (e[0]?.properties !== undefined) {
            //console.log(e)
            setClaimForm({
                ...claimForm,
                pod: `${e[0].properties.country} - ${e[0].properties.name}`
            })
        } else {
            setClaimForm({
                ...claimForm,
                pod: e
            })
            if (e.length >2) {
                handleChangePodAPI(e)
            }
        }
        //change claim on sessionStorage
       // sessionStorage.setItem("claim", JSON.stringify(claimForm))
    }
    const handleBlNumbers = (e) => {
        setBlNumbers(e.target.value)
    }



    // useEffect(() => {
    //     //console.log(blNumbers)
    //     //console.log(blLetters)
    //     if (blLetters.length > 0 && blNumbers !== "") {
    //
    //         setClaimForm({
    //             ...claimForm,
    //             blAwb: `${blLetters}-${blNumbers}`
    //         })
    //     }
    // }, [blLetters, blNumbers])

    useEffect(() => {
        if(claimForm?.blAwb?.length > 0){
            setBlLetters(claimForm.blAwb.split("-")[0]);
            setBlNumbers(claimForm.blAwb.split("-")[1]);
        }
    }, [])

    // useEffect(() => {
    //     //console.log(blLetters)
    //     if (blLetters.length > 0) {
    //         //console.log(blLetters)
    //         setClaimForm({
    //             ...claimForm,
    //             carrierName: blLetters[0].name
    //         })
    //     }
    // }, [blLetters])
    useEffect(() => {
        //console.log("cambia blLetters")
        setClaimForm({
            ...claimForm,
            blAwb: `${blLetters}-${blNumbers}`
        })
    },[blLetters, blNumbers]);


    // //console.log(blLetters)
    // //console.log(lista)
    // //console.log(listaBl)
    // //console.log(listaBlN)
    // //console.log(lista)
    // //console.log(objBl)
    // //console.log(objBlN)
    // //console.log("sisisisi", blLetters)

    let dontShowClaim = true;
    let dontShowRecover = true;

    if(roleAdmin){
        dontShowClaim = false;
        dontShowRecover = false;
    }else if(roleClaim){
        dontShowClaim = false;
        dontShowRecover = false;
    }else if(roleRecover){
        dontShowClaim = false;
        dontShowRecover = false;
    }else{
        dontShowClaim = false;
        dontShowRecover = false;
    }
    const getReceiverList = () => {
        axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/receiver`, {
            headers: {
                Authorization: `${localStorage.getItem("token")}`,
            }
        }).then((res) => {
            //console.log(res.data)
            setReceiverList(res.data)
        }).catch((error) => {
            //console.log(error)
        });
    }


    const getReceiver = (e) => {
        setClaimForm({
            ...claimForm,
            consigneeName: e
        })
        if (e.length > 2) {
            const city = receiverList.filter(receiver => receiver.description.toLowerCase().includes(e.toLowerCase()))
            setListForInput(city)
        }else{
            setListForInput([])
        }
    }
    const setReceiverName = (e) => {
        if (e[0]?.description !== undefined) {
            setClaimForm({
                ...claimForm,
                consigneeName: `${e[0].description}`
            })
        }
        //  sessionStorage.setItem("claim", JSON.stringify(claimForm))
    }
    useEffect(() => {
        getReceiverList()
    }, [])

    //console.log(width)

    return (
        <>
            <div>
                <Row style={{width: '100%', marginBottom: '1.5rem', justifyContent: "center"}}>
                    {width > 768 && width < 900 ? (
                        <>
                            <Col md={12} sm={12} className={styles.polPod}  style={{position: 'relative', display:'flex', alignItems:'center', margin:'auto'}}>
                                <IoMdBoat style={{minWidth: '40px', fontSize: '2rem', color: '#5a68e9'}}/>
                                <span className={styles.texto_inOut} style={{minWidth:'15ch', fontSize:'15px', alignSelf:'center'}}>{traduccion.transport?.salida}</span>
                                <img src={loadingImg} alt="loading" className={styles.loading} style={{
                                    display: loadingPol ? "block" : "none",
                                    position: 'absolute',
                                    top: -20,
                                    bottom: 0,
                                    left: 30,
                                    width: 50,
                                    height: 100
                                }}/>

                                {stuff
                                    ?<span className={styles.inicio_destino}>{stuff.puerto_carga}</span>

                                    :

                                    <div style={{display:"flex", flexDirection:'column'}}>
                                        <Typeahead
                                            style={{marginLeft: '1rem', width: '20ch'}}
                                            className={styles.typeahead2}
                                            id="basic-typeahead1"
                                            defaultInputValue={claimForm?.pol}
                                            labelKey={option => `${option.properties.country} - ${option.properties.name}`}
                                            onChange={handleChangePol}
                                            onInputChange={e => handleChangePol(e)}
                                            options={listaPol}
                                        />
                                        {/*<div className={styles.errors} style={{marginLeft:70}}>{errors?.pol && errors.pol}</div>*/}
                                    </div>
                                }
                            </Col>
                            {error && <span>Error</span>}
                            {width > 1549 &&
                                <Col sm={2} style={{textAlign: "end"}}>
                                    <TbArrowNarrowLeft
                                        style={{width: '2.2rem', transform: 'scaleX(3) scaleY(3)', color: '#b9bffe'}}/>
                                    <TbLineDotted style={{
                                        width: '4.2rem',
                                        transform: 'scaleX(5) scaleY(5)',
                                        color: '#b9bffe',
                                        margin: '0 .3rem'
                                    }}/>
                                    <TbArrowNarrowRight
                                        style={{width: '2.2rem', transform: 'scaleX(3) scaleY(3)', color: '#b9bffe'}}/>
                                </Col>
                            }
                            <Col md={12} className={styles.polPod} style={{position: 'relative', display:'flex', alignItems:'center', margin:'auto'}}>
                                <IoMdBoat style={{minWidth: '40px', fontSize: '2rem', color: '#5a68e9'}}/>
                                <span className={styles.texto_inOut} style={{minWidth:'15ch', fontSize:'15px', alignSelf:'center'}}>{traduccion.transport?.llegada}</span>
                                <img src={loadingImg} alt="loading" className={styles.loading} style={{
                                    display: loadingPod ? "block" : "none",
                                    position: 'absolute',
                                    top: -20,
                                    bottom: 0,
                                    left: 30,
                                    width: 50,
                                    height: 100
                                }}/>

                                {stuff ?
                                    <span className={styles.inicio_destino}>{stuff.puerto_destino}</span>

                                    :<div style={{display:"flex", flexDirection:'column'}}>


                                        <Typeahead
                                            style={{marginLeft: '1rem', width: '20ch'}}
                                            className={styles.typeahead2}
                                            id="basic-typeahead2"
                                            defaultInputValue={claimForm?.pod}
                                            labelKey={option => `${option.properties.country} - ${option.properties.name}`}
                                            onChange={handleChangePod}
                                            onInputChange={e => handleChangePod(e)}
                                            options={listaPod}
                                        />
                                        {/*<div className={styles.errors} style={{marginLeft:70}}>{errors?.pod && errors.pod}</div>*/}
                                    </div>
                                }
                            </Col>
                        </>
                    ) : (
                        <>
                            <Col md={3} sm={12} className={styles.polPod}  style={{position: 'relative', display:'flex', alignItems:'center', margin:'auto'}}>
                                <IoMdBoat style={{minWidth: '40px', fontSize: '2rem', color: '#5a68e9'}}/>
                                <span className={styles.texto_inOut} style={{minWidth:'15ch', fontSize:'15px', alignSelf:'center'}}>{traduccion.transport?.salida}</span>
                                <img src={loadingImg} alt="loading" className={styles.loading} style={{
                                    display: loadingPol ? "block" : "none",
                                    position: 'absolute',
                                    top: -20,
                                    bottom: 0,
                                    left: 30,
                                    width: 50,
                                    height: 100
                                }}/>

                                {stuff
                                    ?<span className={styles.inicio_destino}>{stuff.puerto_carga}</span>

                                    :

                                    <div style={{display:"flex", flexDirection:'column'}}>
                                        <Typeahead
                                            style={{marginLeft: '1rem', width: '20ch'}}
                                            className={styles.typeahead2}
                                            id="basic-typeahead1"
                                            defaultInputValue={claimForm?.pol}
                                            labelKey={option => `${option.properties.country} - ${option.properties.name}`}
                                            onChange={handleChangePol}
                                            onInputChange={e => handleChangePol(e)}
                                            options={listaPol}
                                        />
                                        {/*<div className={styles.errors} style={{marginLeft:70}}>{errors?.pol && errors.pol}</div>*/}
                                    </div>

                                }

                            </Col>
                            {error && <span>Error</span>}
                            {width > 1549 &&
                                <Col sm={2} style={{textAlign: "end"}}>
                                    <TbArrowNarrowLeft
                                        style={{width: '2.2rem', transform: 'scaleX(3) scaleY(3)', color: '#b9bffe'}}/>
                                    <TbLineDotted style={{
                                        width: '4.2rem',
                                        transform: 'scaleX(5) scaleY(5)',
                                        color: '#b9bffe',
                                        margin: '0 .3rem'
                                    }}/>
                                    <TbArrowNarrowRight
                                        style={{width: '2.2rem', transform: 'scaleX(3) scaleY(3)', color: '#b9bffe'}}/>
                                </Col>
                            }
                            <Col md={3} className={styles.polPod} style={{position: 'relative', display:'flex', alignItems:'center', margin:'auto'}}>
                                <IoMdBoat style={{minWidth: '40px', fontSize: '2rem', color: '#5a68e9'}}/>
                                <span className={styles.texto_inOut} style={{minWidth:'15ch', fontSize:'15px', alignSelf:'center'}}>{traduccion.transport?.llegada}</span>
                                <img src={loadingImg} alt="loading" className={styles.loading} style={{
                                    display: loadingPod ? "block" : "none",
                                    position: 'absolute',
                                    top: -20,
                                    bottom: 0,
                                    left: 30,
                                    width: 50,
                                    height: 100
                                }}/>

                                {stuff ?
                                    <span className={styles.inicio_destino}>{stuff.puerto_destino}</span>

                                    :<div style={{display:"flex", flexDirection:'column'}}>


                                        <Typeahead
                                            style={{marginLeft: '1rem', width: '20ch'}}
                                            className={styles.typeahead2}
                                            id="basic-typeahead2"
                                            defaultInputValue={claimForm?.pod}
                                            labelKey={option => `${option.properties.country} - ${option.properties.name}`}
                                            onChange={handleChangePod}
                                            onInputChange={e => handleChangePod(e)}
                                            options={listaPod}
                                        />
                                        {/*<div className={styles.errors} style={{marginLeft:70}}>{errors?.pod && errors.pod}</div>*/}
                                    </div>
                                }
                            </Col>
                        </>
                        )
                    }
                </Row>
                <Container className={styles.container}>

                    <Row className={styles.row}>
                        <Col>
                            <div className={styles.selects}>
                                <div className="pe-2" style={{position: "relative"}}>{traduccion.maritime?.nave}
                                    <span style={{fontSize: "0px"}}>company_name</span>
                                    <img src={loadingImg} alt="loading" className={styles.loading} style={{
                                        display: loading ? "block" : "none",
                                        position: 'absolute',
                                        top: -20,
                                        bottom: 0,
                                        right: 10,
                                        width: 50,
                                        height: 100
                                    }}/>
                                </div>
                                <Form.Group controlId="Naviera">

                                    {stuff ?
                                        <Form.Group className="me-3" controlId="vessel">
                                            <Form.Control type="text" placeholder="vessel" name="vessel"
                                                          onChange={handleChange}
                                                          value={claimForm.vessel}/>
                                        </Form.Group>:

                                        <Form.Group controlId="Recibidor">
                                        <div style={{display:"flex", flexDirection:'column'}}>
                                            <Typeahead
                                                className={styles.typeahead}
                                                id="basic-typeahead"
                                                defaultInputValue={claimForm?.vessel}
                                                labelKey={option => `${option.name}`}
                                                onChange={handleChangeVessel}
                                                onInputChange={e => handleChangeVessel(e)}
                                                options={lista}
                                            />
                                            {/*<div className={styles.errors} style={{marginLeft:70}}>{errors?.vessel && errors.vessel}</div>*/}
                                        </div>
                                        </Form.Group>
                                    }
                                </Form.Group>
                            </div>
                            <div className={styles.selectsBL} style={{marginRight: 0}}>
                                <div className="pe-2">{traduccion.maritime?.nBL}</div>
                                <div style={{display: 'flex'}}>

                                    {/*------------ SCAC CON LA API (Standard Carrier Alpha Code) ----------------*/}
                                    <Typeahead
                                        className={styles.typeahead2}
                                        id="basic-typeahead"
                                        name="BlCodes"
                                        //defaultInputValue={claimForm?.blAwb?.split('-')[0] || blLetters}
                                        selected={objBl}
                                        labelKey={(item) => `${item.scac}`}
                                        options={listaBl}
                                        onInputChange={handleChangeBl}
                                        onChange={handleChangeBlAndCarrier}
                                    />

                                    <Form.Group  controlId="BlNumbers" style={{maxWidth:155}}>
                                        <Form.Control type="text" placeholder="number" name="BlNumbers"
                                                      onChange={handleBlNumbers} value={blNumbers}/>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={styles.selects}>
                                <div className="pe-2">{traduccion?.clients?.exportador}</div>
                                {(imRsk&& exportersName?.affiliatedCompanies?.length==0)?
                                    <p>{claimForm?.companyName}</p>
                                    :
                                    imRsk?
                                        <Form.Select name="companyName" onClick={handleChangeExporter} onChange={handleChange} value={claimForm?.companyId}>
                                            <option value={exportersName.id}>{exportersName.companyName}</option>
                                            {exportersName?.affiliatedCompanies?.map((item, index) => (
                                                <option key={index} value={item.id}>{item.companyName}</option> // 🔹 Usa `exporterName` si existe
                                            ))}
                                        </Form.Select>

                                        :
                                        <div className={styles.selects}>
                                            <Form.Select aria-label="Default select example" name="companyName"
                                                         onClick={handleChangeExporter}
                                                         onChange={handleChange}
                                                         value={claimForm?.companyId}>
                                                <option value={exportersName.id}>{exportersName.companyName}</option>
                                                {exportersName?.affiliatedCompanies?.map((item, index) => {
                                                    return <option key={index} value={item.id}>{item.companyName}</option>

                                                })}
                                            </Form.Select>
                                        </div>
                                    // <Form.Group className="me-3" controlId="companyName">
                                    //     <Form.Control type="text" placeholder="Nombre Exportador" name="companyName"
                                    //                   onChange={handleChange}
                                    //                   value={claimForm?.companyName}
                                    //     />
                                    // </Form.Group>
                                }
                            </div>

                            <div className={styles.selects}>
                                <div className="pe-2">{traduccion.maritime?.recibidor}</div>
                                <Form.Group className="me-0" controlId="Recibidor">
                                <Typeahead
                                    style={{position:"relative"}}
                                    className={styles.typeahead}
                                    id="basic-typeahead"
                                    defaultInputValue={claimForm?.consigneeName}
                                    labelKey={option => `${option.description}`}
                                    onChange={setReceiverName}
                                    onInputChange={e => getReceiver(e)}
                                    options={listForInput}
                                />
                                </Form.Group>
                            </div>

                        </Col>
                        <Col>
                            <div className={styles.selects}>
                                <div className="pe-2">{traduccion.maritime?.fechaBL}</div>
                                <Form.Group className="me-3" controlId="FechaBL">
                                    <Form.Control type="date" name="blAwbDate" onChange={handleChange}
                                                  value={claimForm?.blAwbDate?.substring(0, 10)}
                                                    max={claimForm?.dischargeDate?.substring(0, 10)}
                                        /*value={stuffing.data&& stuffing.data.orden_embarque}*/ />
                                </Form.Group>
                            </div>
                            <div className={styles.selects}>
                                <div className="pe-2">Carrier</div>
                                {/*------------medi----------------*/}
                                <Typeahead
                                    className={styles.typeahead3}
                                    id="basic-typeahead"
                                    name="BlName"
                                    //defaultInputValue={claimForm?.carrierName}
                                    selected={objBlN}
                                    labelKey={(item) => `${item.name}`}
                                    options={listaBlN}
                                    onInputChange={(e)=>handleChangeBlN(e)}
                                    onChange={handleChangeBlAndCarrierN}
                                />

                            </div>
                            <div className={styles.selects}>
                                <div className="pe-2">{traduccion.maritime?.ata}</div>
                                <Form.Group className="me-3" controlId="ATA">
                                    <Form.Control type="date" min={claimForm.blAwbDate} disabled={!imRsk} className={imRsk? styles.selects_input:styles.selects_input_disabled} name="ata" onChange={handleChange}
                                                  value={claimForm?.ata?.substring(0, 10)}  /*value={stuffing.data&& stuffing.data.orden_embarque}*/ />
                                </Form.Group>
                            </div>
                            <div className={styles.selects}>
                                <div className="pe-2">{traduccion.maritime?.timeBar}</div>
                                <Form.Group className="me-3" controlId="Timebar">
                                    <Form.Control type="date"  disabled={!imRsk} className={imRsk? styles.selects_input:styles.selects_input_disabled} name="timeBar" onChange={handleChange}
                                                  value={claimForm?.timeBar?.substring(0, 10)}   /*value={stuffing.data&& stuffing.data.orden_embarque}*/ />
                                </Form.Group>
                            </div>

                        </Col>
                        <Col>
                            <div className={styles.monto_recuperado}>
                                <div>{traduccion.carga?.montoReclamado}</div>
                                <div className={styles.selects_m}>
                                    <div className="pe-2">{traduccion.maritime?.moneda}</div>

                                    <Form.Select  disabled={dontShowClaim} className={styles.moneda} name="currency" onChange={handleChange} value={claimForm?.cargo?.currency} >
                                        <option value="">---</option>
                                        {
                                            currency.map((currency) => (
                                                <option key={currency.id}
                                                        value={currency.id}>{currency?.description}</option>))
                                        }
                                    </Form.Select>
                                </div>
                                <div className={styles.selects_m}>
                                    <div className="pe-2">{traduccion.maritime?.monto}</div>
                                    <Form.Group className={styles.moneda} style={{borderRadius:6}} controlId="Monto">
                                        <Form.Control  disabled={dontShowClaim} type="text" placeholder={traduccion?.maritime?.monto} step=".01" name="reclaimedAmount"
                                                       onChange={handleChange}
                                                       onInput={onlyNumbers}
                                                       value={claimForm?.cargo?.reclaimedAmount}  /*value={stuffing.data&& stuffing.data.orden_embarque}*/ />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={styles.monto_recuperado}>
                                <div>{traduccion.maritime?.montoRecuperado}</div>
                                <div className={styles.selects_m}>
                                    <div className="pe-2">{traduccion.maritime?.moneda}</div>

                                    <Form.Select disabled={!imRsk} className={styles.moneda} name="currencyId" onChange={handleChange} value={claimForm?.currencyId} >
                                        <option value="">---</option>
                                        {
                                            currency.map((currency) => (
                                                <option key={currency.id}
                                                        value={currency.id}>{currency?.description}</option>))
                                        }
                                    </Form.Select>
                                </div>
                                <div className={styles.selects_m}>
                                    <div className="pe-2">{traduccion.maritime?.monto}</div>
                                    <Form.Group className={styles.moneda} style={{borderRadius:6}} controlId="Monto">
                                        <Form.Control disabled={!imRsk} type="text" placeholder={traduccion?.maritime?.monto} name="recoveredAmount"
                                                      onChange={handleChange}
                                                      onInput={onlyNumbers}
                                                      value={claimForm?.recoveredAmount}  /*value={stuffing.data&& stuffing.data.orden_embarque}*/ />
                                    </Form.Group>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
